import { msgAlertInput } from 'constants/messageAlert';
import CheckBox from 'components/checkbox/CheckBox';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormValuesRegister, RegisterPayload } from 'models/authModel';
import { colors } from 'constants/theme';
import { css } from '@emotion/css';
import { isMobile } from 'react-device-detect';
type InputId = 'un' | 'pwd' | 'zjpwd' | 'nickname' | 'phone' | 'email';
interface InputItem {
    id: InputId;
    label: string;
    holder: string;
    inputType: string;
    require: boolean;
    togglePass?: boolean;
    pattern?: { value: RegExp; message: string };
    minLength?: { value: number; message: string };
    maxLength?: { value: number; message: string };
}
const listInput: InputItem[] = [
    {
        id: 'un',
        label: 'Tên Đăng Nhập',
        holder: msgAlertInput.username.invalid,
        inputType: 'text',
        require: true,
        pattern: {
            value: /^\S+$/,
            message: msgAlertInput.generic.invalid('Tên đăng nhập'),
        },

        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'pwd',
        label: 'Mật Khẩu',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'zjpwd',
        label: 'Mật Khẩu Thanh Toán',
        holder: msgAlertInput.password.invalid,
        inputType: 'password',
        require: true,
        togglePass: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'nickname',
        label: 'Biệt Danh',
        holder: 'Nhập ký tự chữ và số',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 30,
            message: msgAlertInput.generic.maxLength(30),
        },
    },
    {
        id: 'phone',
        label: 'Số Điện Thoại',
        holder: 'Nhập vào số điện thoại',
        inputType: 'text',
        require: true,
        maxLength: {
            value: 12,
            message: msgAlertInput.generic.maxLength(12),
        },
    },
    {
        id: 'email',
        label: 'Email (Không bắt buộc)',
        holder: 'Nhập đúng định dạng email',
        inputType: 'email',
        require: false,
    },
];
interface Props {
    onRegister: (payload: RegisterPayload) => void;
}
export default function RegisterForm(props: Props) {
    const { onRegister } = props;

    const [isCheckedTOF, setIsCheckedTOF] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [showPassPay, setShowPassPay] = useState(false);
    const {
        handleSubmit,
        register,
        setFocus,
        setError,
        formState: { errors },
    } = useForm<FormValuesRegister>();

    const handleRegister: SubmitHandler<FormValuesRegister> = async (values) => {
        // if (!isCheckedTOF) {
        //     toast.error('Vui lòng chọn chấp nhận các điều khoản');
        //     return;
        // }
        if (values.pwd === values.zjpwd) {
            setFocus('zjpwd');
            setError('zjpwd', {
                type: 'value',
                message: 'Mật khẩu thanh toán không được giống với đăng nhập',
            });
            return;
        }

        let fatherId = window.location.pathname.replace('/', '');
        if (!fatherId) {
            fatherId = !isMobile ? '1788190' : '1788180';
        }
        console.log(fatherId);
        const payload = {
            ...values,
            loginname: values.un.toLowerCase(),
            email: values.email || `${values.un.toLowerCase().trim()}_noreply@gmail.com`,
            agentid: '10020', // prod
            id: fatherId,
        };
        onRegister(payload);
    };
    return (
        <form className={styles.form} onSubmit={handleSubmit(handleRegister)}>
            {listInput.map((item, index) => (
                <div className={styles.inputWrap} key={index}>
                    <label htmlFor={item.id}>{item.label}</label>
                    <input
                        type={item.id === 'pwd' ? (!showPass ? item.inputType : 'text') : item.id === 'zjpwd' ? (!showPassPay ? item.inputType : 'text') : item.inputType}
                        id={item.id}
                        placeholder={item.holder}
                        className={`input-common ${styles.input}`}
                        {...register(item.id, {
                            required: item.require,
                            pattern: item.pattern,
                            maxLength: item.maxLength,
                            minLength: item.minLength,
                        })}
                    />
                    {/* toggle password login */}
                    {item.togglePass && item.id === 'pwd' && (
                        <div className={styles.togglePass} onClick={() => setShowPass(!showPass)}>
                            {showPass ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    )}

                    {/* toggle password pay */}
                    {item.togglePass && item.id === 'zjpwd' && (
                        <div className={styles.togglePass} onClick={() => setShowPassPay(!showPassPay)}>
                            {showPassPay ? <img src={require('assets/images/icons/eye_on.png')} alt="" /> : <img src={require('assets/images/icons/eye_off.png')} alt="" />}
                        </div>
                    )}

                    {/* base error  */}
                    <div className={styles.errorInput}>{(errors as any)[item.id] && <small>{(errors as any)[item.id].message || 'Vui lòng nhập trường này!'}</small>}</div>
                </div>
            ))}
            {/* <CheckBox isChecked={isCheckedTOF} onToggleCheck={(value) => setIsCheckedTOF(value)}>
                <p className={styles.textTOF}>
                    Tôi đã đọc và đồng ý với các <span>Điều khoản và Điều kiện</span> của Wibo88
                </p>
            </CheckBox> */}

            <button type="submit" className={styles.btnSubmit} style={{ marginTop: '12px' }}>
                Đăng Ký
            </button>
        </form>
    );
}

const styles = {
    wrap: css`
        border-radius: 24px;
        overflow: hidden;
        display: flex;
    `,
    left: css`
        flex: 1;
        display: flex;
        flex-direction: column;
    `,
    logoPartner: css`
        display: flex;
        flex-wrap: wrap;

        background: ${colors.white};
        padding: 12px 30px;
    `,
    partnerItem: css`
        flex: 0 0 20%;
        max-width: 20%;
        padding: 4px 8px;
    `,
    itemInner: css`
        border: 1px solid #e0e0e0;
        padding: 2px 8px;
        border-radius: 4px;
        img {
            width: 100%;
            height: 27px;
            object-fit: contain;
        }
    `,

    heading: css`
        display: flex;
        align-items: center;
    `,
    title: css`
        position: relative;
        display: flex;
        flex: 1;
        border: 1px solid ${colors.primary};
        border-radius: 8px;
    `,
    titleBg: css`
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    `,
    titlecontent: css`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        h6 {
            font-size: 20px;
            color: ${colors.white};
            font-weight: 500;
        }
        img {
            width: 108px;
        }
    `,
    btnClose: css`
        width: 40px;
        height: 40px;
        margin-left: 12px;
        cursor: pointer;
    `,
    hasAccount: css`
        display: flex;
        align-items: center;
        margin: 12px 0;
        padding-left: 12px;
        span {
            font-size: 14px;
            color: ${colors.white};
        }
        p {
            color: ${colors.yellow};
            font-weight: 600;
            padding: 4px 8px;
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
    `,
    form: css`
        color: ${colors.white};
    `,
    inputWrap: css`
        position: relative;
        padding-bottom: 20px;
        label {
            display: block;
            margin-bottom: 6px;
            font-weight: 300;
        }
        &:-internal-autofill-selected {
            background: none;
        }
    `,
    input: css`
        width: 100%;
        background-color: ${colors.background};
        padding: 8px;
        border: none;
        color: ${colors.white};
        font-size: 16px;
        resize: none;
        border-radius: 8px;
        &:focus {
            outline: none;
        }
    `,

    errorInput: css`
        position: absolute;
        bottom: 4px;
        left: 2px;
        width: 100%;
        small {
            font-size: 12px;
            color: ${colors.notice};
        }
    `,
    textTOF: css`
        font-size: 14px;
        font-weight: 300;
    `,

    togglePass: css`
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 46px;
        transform: translateY(-50%);
        img {
            width: 18px;
        }
    `,
    btnSubmit: css`
        padding: 14px 0;
        font-weight: 500;
        border-radius: 8px;
        cursor: pointer;
        background: ${colors.primary};
        color: ${colors.blackText};
        width: 100%;
        cursor: pointer;
        border: none;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 16px;
        &:hover {
            background: #ffc107;
        }
        &:disabled {
            pointer-events: none;
            opacity: 0.7;
        }
        @media (max-width: 420px) {
            padding: 12px 0;
        }
    `,
};
