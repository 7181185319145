import { css } from '@emotion/css';

const loadingStyle = {
    container: css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 1000;

        display: flex;
        align-items: center;
        justify-content: center;
    `,
    loader: css`
        width: 80px;
        height: 80px;
    `,
    icon: css`
        width: 80px;
    `,
};
export default loadingStyle;
