export const msgAlertInput = {
    generic: {
        invalid: (typeInput: string) => `${typeInput} không hợp lệ`,
        require: 'Vui lòng nhập trường này',
        noWhiteSpace: 'Không được chứa khoảng trắng',
        maxLength: (num: number) => `Tối đa ${num} ký tự`,
        minLength: (num: number) => `Tối thiểu ${num} ký tự`,
    },
    username: {
        invalid: 'Từ 5 đến 30 ký tự và không chứa khoảng trắng',
        pattern: /^\S+$/,
    },
    nickname: {
        invalid: 'Nhập ký tự chữ và số',
    },
    password: {
        invalid: 'Từ 6 đến 30 ký tự chữ, số hoặc ký tự đặc biệt',
    },
    prepassword: {
        invalid: 'Xác nhận mật khẩu không đúng',
    },
    phone: {
        maxLength: 'Tối đa 11 ký tự số',
        minLength: 'Tối thiểu 10 ký tự số',
    },
};

export const msgAuth = {
    login: 'Vui lòng đăng nhập',
};
