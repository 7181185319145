export const colors = {
    primary: '#fecd2f',
    orange: '#ff8900',
    secondary: '#6f68f8',
    notice: '#fc2569',
    border: '#2a2a57',
    background: '#0f0f33',
    yellow: '#ffbf61',
    gray: '#4e5461',
    blackText: '#1a202c',
    white: '#fff',
    black: '#000',
};
