import ReactDOM from 'react-dom';
import styles from './loading.style';
import loading from 'assets/images/icons/loading.svg';
function LoadingIcon() {
    return ReactDOM.createPortal(
        <div className={styles.container}>
            <div className={styles.loader} />
            <img className={styles.icon} src={loading} alt="" />
        </div>,
        document.body
    );
}

export default LoadingIcon;
