import { AxiosResponse } from 'axios';
import { BaseResponse } from 'models/baseResponse';
import axiosClient from './aixosClient';

const authApi = {
    login(payload: { apiUrl: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'LoginBean_loginnew';
        return axiosClient.post(`${payload.apiUrl}/UserService.aspx`, payload);
    },
    signUp(apiUrl: string, payload: any): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'RegBean_regNew';
        return axiosClient.post(`${apiUrl}/RegisterService.aspx`, payload);
    },
    getDomainAvailable(payload: { apiUrl: string; flag?: string }): Promise<AxiosResponse<BaseResponse<any>>> {
        payload.flag = 'DomainBean_listDomain';
        return axiosClient.post(`${payload.apiUrl}/UserService.aspx`, payload);
    },
};
export default authApi;
